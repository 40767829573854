import { useState, useCallback } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const useFetchCategories = () => {
    const axiosPrivate = useAxiosPrivate();
    const [fetchedCategories, setFetchedCategories] = useState([]);

    const fetchCategories = useCallback(async () => {
        const controller = new AbortController();
        let isMounted = true;

        const getCategories = async () => {
            try {
                const response = await axiosPrivate.get('/app/recipe_helper/recipe_categories', {
                    signal: controller.signal,
                });

                if (isMounted) {
                    setFetchedCategories(response.data);
                }
            } catch (err) {
                throw(err);
            }
        };

        try {
            return await new Promise((resolve, reject) => {
                getCategories().then(resolve).catch(reject);
            });
        } finally {
            isMounted = false;
            controller.abort();
        }
    }, [axiosPrivate]);

    return {
        fetchedCategories,
        fetchCategories,
    };
};

export default useFetchCategories;