import { useRef, useState, useEffect } from 'react';

import AlertMessage from '../components/AlertMessage';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { ingredients_regex } from '../utils/regex';

const GenerateRecipe = () => {
    const axiosPrivate = useAxiosPrivate();
    const scrollableDivRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [alertMsg, setAlertMsg] = useState('');
    const [generatedRecipe, setGeneratedRecipe] = useState('');
    const [ingredientsJson, setIngredientsJson] = useState({
        ingredients: ''
    });

    const handleInputChange = (e) => {
        setIngredientsJson(prevJson => ({
            ...prevJson,
            [e.target.name]: e.target.value
        }));
    }

    const handleSubmitForm = async (e) => {
        e.preventDefault();
        document.getElementById('menuToggle').checked = false;
        
        if (!ingredients_regex.test(ingredientsJson.ingredients)) {
            setAlertMsg('Invalid ingredients format: must be a comma-separated list of ingredients');
            return;
        }

        try {
            setIsLoading(true);
            const response = await axiosPrivate.post('/app/recipe/generate', ingredientsJson);
            // setGeneratedRecipe('This is a test recipe');
            setGeneratedRecipe(response.data);
            // console.log(response.data.recipe);
        } catch (err) {
            setAlertMsg(err.response?.data?.detail || 'error generating recipe');
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        const handleCloseDisplayRecipe = () => {
            console.log('closeDisplayRecipe');
            document.getElementById('cardOverlay').classList.remove('show');
            setGeneratedRecipe('');
        };
        
        if (generatedRecipe) {
            const { name, description, serving_size, ingredients, instructions } = generatedRecipe;
            console.log('here');
        
            if (name && description && serving_size && ingredients && instructions) {
                document.getElementById('menuToggle').checked = true;
                window.addEventListener('closeDisplayRecipe', handleCloseDisplayRecipe);
            } else {
                setGeneratedRecipe('');
                setAlertMsg('There was an error generating the recipe');
            }
        }
    }, [generatedRecipe])

    useEffect(() => {
        setIsLoading(false);
    }, [])

    return (
        <>
            <AlertMessage message={alertMsg} category="error" onClose={() => setAlertMsg('')}/>
            <section ref={scrollableDivRef} className={`section-display disable-select ${isLoading ? 'loading' : ''}`}>
                <div id="cardOverlay" className={`card-overlay ${generatedRecipe ? "show" : ""}`}>
                    {generatedRecipe && (
                        <div className="recipe-detail disable-select">
                            <div className="recipe-detail-content">
                                <h1>{generatedRecipe.name}</h1>
                                <p>{generatedRecipe.description}</p>
                                <p>Serving Size: {generatedRecipe.serving_size}</p>
                                <div className="recipe-detail-ingredients">
                                    <h3>Ingredients</h3>
                                    <ul className="ingredients-list">
                                        {generatedRecipe.ingredients.map((ingredient) => (
                                            <li key={ingredient.id}>{ingredient}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="recipe-detail-instructions">
                                    <h3>Instructions</h3>
                                    <ul>
                                        {generatedRecipe.instructions.map((instruction) => (
                                            <li key={instruction.id}>{instruction}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <form onSubmit={handleSubmitForm} className="generate-form">
                    <textarea
                        id="ingredients" 
                        name="ingredients" 
                        onChange={handleInputChange} 
                        placeholder="Enter ingredients separated by commas to generate a recipe. These recipes are generated by AI and may not be accurate. User discretion is advised." 
                        value={ingredientsJson.ingredients}
                        required
                    />
                    <button 
                        className="create-button disable-select" 
                        disabled={isLoading ? true : false}
                    >
                        Generate Recipe
                    </button>
                </form>
            </section>
        </>
    )
}

export default GenerateRecipe