import '../assets/styles/unauthorized.css';
// import { useNavigate } from 'react-router-dom'

const Unauthorized = () => {
    // const navigate = useNavigate();

    // const goBack = () => navigate(-1);

    return (
        <section className="section-unauthorized disable-select">
            <p>You do not have access to the requested page.</p><br></br>
        </section>
    )
}

export default Unauthorized
