import { useEffect, useRef } from 'react'

const DisplayRecipe = ({ recipe, ingredientUnits, isLoading }) => {
    const bannerImage = useRef(null);

    const getUnitName = (unit_id) => {
        const unit = ingredientUnits.find(unit => unit.id === unit_id);
        return unit ? unit.name : '';
    };
    
    useEffect(() => {
        const imageUrl = `data:${recipe.banner_image_type};base64,${recipe.banner_image}`;
        
        if (bannerImage.current) {
            bannerImage.current.src = imageUrl;
            bannerImage.current.focus();
        }
    }, [recipe]);

    return (
        <>
            {isLoading ? (
                <div className={`recipe-detail ${isLoading && 'loading'}`}>&nbsp;</div>
            ) : (
                <div className="recipe-detail disable-select">
                    <div className="recipe-detail-banner">
                        <img
                            ref={bannerImage}
                            alt={recipe.title}
                        />
                    </div>
                    <div className="recipe-detail-content">
                        <h1>{recipe.name}</h1>
                        <p>{recipe.description}</p>
                        <p>Serving Size: {recipe.serving_size}</p>
                        <div className="recipe-detail-ingredients">
                            <h3>Ingredients</h3>
                            <ul className="ingredients-list">
                                {recipe.ingredients.map((ingredient) => (
                                    <li key={ingredient.id}>
                                        {`${ingredient.quantity} ${getUnitName(ingredient.unit_id)} ${ingredient.name}`}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="recipe-detail-instructions">
                            <h3>Instructions</h3>
                            <ul>
                                {recipe.instructions.map((instruction) => (
                                    <li key={instruction.id}>{instruction.description}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default DisplayRecipe