export const base_url = 'https://oauth.mycodeplaypen.com';
// export const base_url = 'http://127.0.0.1:8000';
export const login_url = '/auth/token';
export const tenant = '81e8a18b-7a75-44e9-addf-121ccc263dba';

export const roles = {
    'USER': 1571,
    'ADMIN': 3413,
    'ORG_ADMIN': 7817
}
