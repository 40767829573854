import { useState, useCallback } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const useFetchIngredientUnits = () => {
    const axiosPrivate = useAxiosPrivate();
    const [fetchedIngredientUnits, setFetchedIngredientUnits] = useState([]);

    const fetchIngredientUnits = useCallback(async () => {
        const controller = new AbortController();
        let isMounted = true;

        const getIngredientUnits = async () => {
            try {
                const response = await axiosPrivate.get('/app/recipe_helper/ingredient_units', {
                    signal: controller.signal,
                });

                if (isMounted) {
                    setFetchedIngredientUnits(response.data);
                }
            } catch (err) {
                throw(err);
            }
        };

        try {
            return await new Promise((resolve, reject) => {
                getIngredientUnits().then(resolve).catch(reject);
            });
        } finally {
            isMounted = false;
            controller.abort();
        }
    }, [axiosPrivate]);

    return {
        fetchedIngredientUnits,
        fetchIngredientUnits,
    };
};

export default useFetchIngredientUnits;