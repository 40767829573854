import { useEffect, useState, useRef } from 'react';

import DisplayCard from '../components/DisplayCard';
import DisplayRecipe from '../components/DisplayRecipe';
import useFetchCategories from '../hooks/useFetchCategories';
import useFetchIngredientUnits from '../hooks/useFetchIngredientUnits';
import useFetchRecipeDetail from '../hooks/useFetchRecipeDetail';
import useFetchRecipes from '../hooks/useFetchRecipes';

import '../assets/styles/home.css';

const Home = () => {
    const { fetchedCategories, fetchCategories } = useFetchCategories();
    const { fetchedIngredientUnits, fetchIngredientUnits } = useFetchIngredientUnits();
    const { fetchedRecipeDetail, setFetchedRecipeDetail, fetchRecipeDetail } = useFetchRecipeDetail();
    const { fetchedRecipes, fetchRecipes } = useFetchRecipes();
    const scrollableDivRef = useRef(null);
    const [clickedCategories, setClickedCategories] = useState([]);
    const [displayedRecipeDetail, setDisplayedRecipeDetail] = useState([]);
    const [errMsg, setErrMsg] = useState('');
    const [fetchedRecipeDetails, setFetchedRecipeDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [showRecipeDetail, setShowRecipeDetail] = useState(false);
    
    const handleRecipeClick = async (recipeId) => {
        setIsLoading(true);
        setDisplayedRecipeDetail([]);
        document.getElementById('cardOverlay').classList.add('show');
        if (document.getElementById('sidebarMenu').classList.contains('translatein')) {
            document.getElementById('sidebarMenu').classList.remove('translatein');
        }
        document.getElementById('menuToggle').checked = true;
        document.getElementById('categoryMenu')?.classList.add('category-container-hide');
        if (!fetchedRecipeDetails.some(recipe => recipe.id === recipeId)) {
            await fetchRecipeDetail(recipeId);
        } else {
            setDisplayedRecipeDetail(fetchedRecipeDetails.find(recipe => recipe.id === recipeId));
            setShowRecipeDetail(true);
            setIsLoading(false);
        }
    };

    const handleCategoryClick = async (categoryId) => {
        setErrMsg('');
        setIsLoading(true);
        setSelectedCategory(categoryId);
        const isCategoryClicked = clickedCategories.includes(categoryId);
    
        if (!isCategoryClicked) {
            try {
                await fetchRecipes(false, categoryId);
            } catch (err) {
                setIsLoading(false);
                if (err.response?.status === 404) {
                    setErrMsg('There are no recipes for this category.');
                } else {
                    setErrMsg('There is a problem with the application. Please try again later.');
                }
            }
            setClickedCategories(prevClickedCategories => [...prevClickedCategories, categoryId]);
        } else {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (fetchedRecipes.length) {
            setIsLoading(false);
        }
    }, [fetchedRecipes]);

    useEffect(() => {
        if (fetchedRecipeDetail) {
            if (document.getElementById('menuToggle').checked) {          
                setDisplayedRecipeDetail(fetchedRecipeDetail);
                setFetchedRecipeDetails(prevRecipeDetails => [...prevRecipeDetails, fetchedRecipeDetail]);
                setShowRecipeDetail(true);
                setIsLoading(false);
            } else {
                setFetchedRecipeDetail(null);
                setDisplayedRecipeDetail([]);
                setShowRecipeDetail(false);
                setIsLoading(false);
            }
        }
    }, [fetchedRecipeDetail, setFetchedRecipeDetail]);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([fetchCategories(), fetchIngredientUnits()]);
                setIsLoading(false);
            } catch (error) {
                setErrMsg('There is a problem with the application. Please try again later.');
                setIsLoading(false);
            }
        };

        const handleCloseDisplayRecipe = () => {
            setDisplayedRecipeDetail([]);
            setFetchedRecipeDetail(null);
            setShowRecipeDetail(false);
            setIsLoading(false);
            document.getElementById('cardOverlay').classList.remove('show');
            document.getElementById('categoryMenu')?.classList.remove('category-container-hide');
        };

        fetchData();
        window.addEventListener('closeDisplayRecipe', handleCloseDisplayRecipe);

        return () => {
            window.removeEventListener('closeDisplayRecipe', handleCloseDisplayRecipe);
        };
    }, [fetchCategories, fetchIngredientUnits, setFetchedRecipeDetail]);

    useEffect(() => {
        const handleScroll = () => {
            if (scrollableDivRef.current.scrollTop > 10) {
                document.getElementById('categoryMenu')?.classList.add('category-container-floating');
            } else {
                document.getElementById('categoryMenu')?.classList.remove('category-container-floating');
            }
        };

        document.getElementById('categoryMenu')?.classList.remove('category-container-floating');
        const scrollableDiv = scrollableDivRef.current;

        if (scrollableDiv) {
            scrollableDiv.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (scrollableDiv) {
                scrollableDiv.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    return (
        <>
            <div id="categoryMenu" className={`category-container `}>
                {fetchedCategories.length ? (
                    fetchedCategories.map((category) => (
                        <button 
                            key={category.id} 
                            onClick={() => {handleCategoryClick(category.id)}}
                            className={selectedCategory === category.id ? 'selected' : ''}
                        >
                            {category.name}
                        </button>
                    ))
                ) : (
                    <button className="category-button-placeholder" disabled>&nbsp;</button>
                )}
            </div>
            <section ref={scrollableDivRef} className={`section-display disable-select ${isLoading ? 'loading' : ''}`}>
                <div id="cardOverlay" className={`card-overlay ${showRecipeDetail ? "show" : ""}`}>
                    {showRecipeDetail && displayedRecipeDetail ? (
                        <DisplayRecipe
                            recipe={displayedRecipeDetail} 
                            ingredientUnits={fetchedIngredientUnits} 
                            isLoading={isLoading}
                        />
                    ) : (
                        <div className={`recipe-detail ${isLoading && 'loading'}`}>&nbsp;</div>
                    )}
                </div>
                {fetchedCategories.length && fetchedIngredientUnits.length && !selectedCategory ? (
                    <div className="welcome-container">
                        <p>Welcome to your ultimate recipe hub! Whether you're looking to recreate family favorites or discover new dishes, we've got you covered.</p>
                        <p>Browse through the categories to find a variety of recipes, from quick weeknight meals to indulgent desserts, and get inspired for any occasion.</p>
                        <p>Start exploring and unlock flavorful recipes that will make cooking at home easier and more enjoyable. What will you cook today?</p>
                    </div>
                ) : fetchedCategories.length && fetchedIngredientUnits.length && selectedCategory ? (
                    <div className="card-container">
                        {fetchedRecipes.filter(recipe => recipe.category_id === selectedCategory).length ? (
                            fetchedRecipes.filter(recipe => recipe.category_id === selectedCategory).map((recipe) => (
                                <DisplayCard 
                                    key={recipe.id}
                                    recipe={recipe} 
                                    onClick={() => handleRecipeClick(recipe.id)}
                                />
                            ))
                        ) : (
                            !isLoading && (
                                <div className="no-recipes-container">
                                    <p>
                                    {errMsg === "There are no recipes for this category."
                                        ? errMsg
                                        : errMsg
                                        ? "There is a problem with the application. Please try again later"
                                        : "There are no recipes for this category."}
                                    </p>
                                </div>
                            )
                        )}
                    </div>
                ) : (
                    <div className="welcome-container"> 
                        <p>{errMsg}</p>
                    </div>
                )}
            </section>
        </>
    )
}

export default Home
