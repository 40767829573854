import '../assets/styles/recipeDisplay.css';

const DisplayCard = ({ recipe, onClick }) => {
    const imageUrl = `data:${recipe.banner_image_type};base64,${recipe.banner_image}`;
    
    return (
        <>
            <div className="card" onClick={onClick}>
                <div
                    className="card-background"
                    style={{ backgroundImage: `url(${imageUrl})` }}
                >
                </div>
                <div className="card-content">    
                    <h2>{recipe.name}</h2>
                    <p>{recipe.description}</p>
                </div>
            </div>
        </>
    )
}

export default DisplayCard