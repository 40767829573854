import useAuth from './useAuth';
import axios from '../utils/axios';

const useLogout = () => {
    const { setAuth } = useAuth();

    const logout = async () => {
        try {
            await axios('/auth/logout', {
                withCredentials: true
            });
        } finally {
            setAuth({});
        }
    }

    return logout;
}

export default useLogout