import { useNavigate } from 'react-router-dom';

import { jwtDecode } from 'jwt-decode';

import useAuth from './useAuth';
import useLogout from './useLogout';
import { tenant } from '../utils/appConfig';
import axios from '../utils/axios';

const useRefreshToken = () => {
    const { setAuth } = useAuth();
    const logout = useLogout();
    const navigate = useNavigate();

    const refresh = async () => {
        try {
            const response = await axios.get('/auth/token/refresh', {
                withCredentials: true
            });

            const access_token = response.data.access_token;
            const decoded = jwtDecode(access_token);
            if (decoded?.aud !== tenant) {
                await logout();
                setAuth(null);
                navigate('/');
                return;
            }

            const roles = decoded?.roles || [];
            const rolesToInt = roles.map(role => parseInt(role));
            const userObject = {
                id: decoded?.sub,
                aud: decoded?.aud,
                name: decoded?.name,
                roles: rolesToInt
            }

            setAuth({ user: userObject, access_token });

            return response.data.access_token;
        } catch (err) {
            setAuth(null);
            navigate('/');
        }
    }

    return refresh;
};

export default useRefreshToken;
