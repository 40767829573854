import React, { useEffect, useState } from 'react';

import AlertMessage from '../components/AlertMessage';
import CreateRecipe from '../components/CreateRecipe';
import EditCard from '../components/EditCard';
import EditRecipe from '../components/EditRecipe';
import useFetchCategories from '../hooks/useFetchCategories';
import useFetchIngredientUnits from '../hooks/useFetchIngredientUnits';
import useFetchRecipes from '../hooks/useFetchRecipes';
import useFetchRecipeDetail from '../hooks/useFetchRecipeDetail';

import '../assets/styles/management.css';

const AdminDashboard = () => {
    const { fetchedCategories, fetchCategories } = useFetchCategories();
    const { fetchedIngredientUnits, fetchIngredientUnits } = useFetchIngredientUnits();
    const { fetchedRecipes, setFetchedRecipes, fetchRecipes } = useFetchRecipes();
    const { fetchedRecipeDetail, fetchRecipeDetail } = useFetchRecipeDetail();
    const [alertMsg, setAlertMsg] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [recipe, setRecipe] = useState({});
    const [showRecipeDetail, setShowRecipeDetail] = useState(false);

    const handleAlert = (msg) => {
        if (msg) {
            setAlertMsg(msg);
        } else {
            if (alertMsg) {
                setAlertMsg('');
            }
        }
    };

    const handleClickDetails = async (recipeId, isEditing = false) => {
        setIsLoading(true);
        setIsEditing(isEditing);
        document.getElementById('cardOverlay').classList.add('show');
        if (document.getElementById('sidebarMenu').classList.contains('translatein')) {
            document.getElementById('sidebarMenu').classList.remove('translatein');
        }
        document.getElementById('menuToggle').checked = true;
        try {
            await fetchRecipeDetail(recipeId);
        } catch (err) {
            document.getElementById('cardOverlay').classList.remove('show');
            document.getElementById('menuToggle').checked = false;
            setShowRecipeDetail(false);
            setIsLoading(false);
            handleAlert(err.response?.data?.detail || 'error fetching recipe detail');
        }
    };

    useEffect(() => {
        if (fetchedRecipeDetail) {
            setRecipe(fetchedRecipeDetail);
            setShowRecipeDetail(true);
            setIsLoading(false);
        }
    }, [fetchedRecipeDetail]);

    const handleClickCreate = () => {
        setAlertMsg('');
        setIsFormVisible(true);
        document.getElementById('cardOverlay').classList.add('show');
        if (document.getElementById('sidebarMenu').classList.contains('translatein')) {
            document.getElementById('sidebarMenu').classList.remove('translatein');
        }
        document.getElementById('menuToggle').checked = true;
    };

    const handleCreateCanceled = () => {
        setAlertMsg('');
        setIsFormVisible(false);
    };

    const handleRecipeCreated = (newRecipe) => {
        setAlertMsg('');
        setIsFormVisible(false);
        setFetchedRecipes([...fetchedRecipes, newRecipe]);
    };

    const handleRecipeDeleted = (id) => {
        setAlertMsg('');
        setFetchedRecipes(fetchedRecipes.filter((recipe) => recipe.id !== id));
    };

    const handleRecipeUpdated = (updatedRecipe) => {
        setAlertMsg('');
        setFetchedRecipes(fetchedRecipes.map((recipe) => recipe.id === updatedRecipe.id ? updatedRecipe : recipe));
    };
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([fetchCategories(), fetchIngredientUnits(), fetchRecipes(true)]);
                setIsLoading(false);
            } catch (error) {
                if (error.response?.data?.detail) {
                    setErrMsg(error.response.data.detail);
                } else {
                    setErrMsg('error fetching recipe data');
                }
                setIsLoading(false);
            }
        };

        fetchData();
    }, [fetchCategories, fetchIngredientUnits, fetchRecipes]);

    return (
        <>
            <AlertMessage message={alertMsg} category="error" onClose={() => setAlertMsg('')}/>
            <section className="section-edit disable-select">
                <div id="cardOverlay" className={`card-overlay ${showRecipeDetail || isFormVisible ? "show" : ""}`}>
                    {isFormVisible ? (
                        <CreateRecipe 
                            categories={fetchedCategories}
                            onCreate={handleRecipeCreated} 
                            onClose={handleCreateCanceled} 
                            onAlert={handleAlert}
                        />
                    ) : showRecipeDetail ? (
                        <EditRecipe 
                            recipe={recipe} 
                            categories={fetchedCategories} 
                            ingredientUnits={fetchedIngredientUnits} 
                            isEditing={isEditing} 
                            onUpdate={handleRecipeUpdated} 
                            onClose={() => {setShowRecipeDetail(false)}} 
                            onAlert={handleAlert}
                        />
                    ) : (
                        <div className={`recipe-detail ${isLoading ? "loading" : ""}`}>&nbsp;</div>
                    )}
                </div>
                <div className="edit-container">
                    <div className="edit-header">
                        <h1>Admin Dashboard</h1>
                        <button 
                            onClick={handleClickCreate} 
                            className="create-button disable-select" 
                            disabled={fetchedCategories.length ? false : true}
                        >
                            Create Recipe
                        </button>
                    </div>
                    <div className="edit-list">
                        {fetchedCategories.length && fetchedRecipes.length && fetchedIngredientUnits.length ? (
                            <ul className="item-list">
                                {fetchedRecipes.map((recipe) => (
                                    <li key={recipe.id} className="list-item disable-select">
                                        <EditCard 
                                            key={recipe.id} 
                                            recipe={recipe} 
                                            categories={fetchedCategories} 
                                            onUpdate={handleRecipeUpdated} 
                                            onDelete={handleRecipeDeleted} 
                                            onAlert={handleAlert} 
                                            onClickDetails={handleClickDetails}/>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <ul className="item-list">
                                <li className={`list-item ${isLoading ? 'loading' : ''}`}>
                                    <div className="item-icons-container">
                                        <p>{errMsg}&nbsp;</p>
                                    </div>
                                </li>
                            </ul>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}

export default AdminDashboard