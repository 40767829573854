import '../assets/styles/confirmation.css';

const Confirmation = ({ message, onConfirm, onCancel }) => {
    return (
        <div className="div-confirm">
            <p>{message}</p>
            <div className="button-group">
                <button onClick={onConfirm} className="confirm-button">Confirm</button>
                <button onClick={onCancel} className="cancel-button">Cancel</button>
            </div>
        </div>
    );
};

export default Confirmation;
