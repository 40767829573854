import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

import '../assets/styles/navbar.css';

const Navbar = () => {
    const { auth } = useAuth();
    const checkboxRef = useRef();
    const [isFloating, setIsFloating] = useState(false);

    const handleMenuToggle = (event) => {
        if (checkboxRef.current) {
            const isRecipeDisplayPage = window.location.pathname === '/home' || window.location.pathname === '/' || window.location.pathname === '/admin_dashboard' || window.location.pathname === '/generate_recipe';
            const isRecipeOpen = document.getElementById('cardOverlay')?.classList.contains('show');
            
            if (event.target.id === 'menuToggle') {
                if (checkboxRef.current.checked) {
                    document.getElementById('sidebarMenu').classList.add('translatein');
                } else {
                    document.getElementById('sidebarMenu').classList.remove('translatein');
                    if (isRecipeDisplayPage && isRecipeOpen) {
                        const event = new CustomEvent('closeDisplayRecipe');
                        window.dispatchEvent(event);
                    }
                }
            } else {
                if (checkboxRef.current.checked) {
                    checkboxRef.current.click();
                }
            }
            
            // console.log('checkboxRef.current.checked', checkboxRef.current.checked);
            // const isMenuClosed = checkboxRef.current.checked;
            // // console.log(isMenuClosed);
            // const isRecipeDisplayPage = window.location.pathname === '/home' || window.location.pathname === '/';
            // const isRecipeOpen = document.getElementById('cardOverlay')?.classList.contains('show');
            
            // if (isMenuClosed) {
            //     document.getElementById('sidebarMenu').classList.add('translatein');
            // } else {
            //     // document.getElementById('cardOverlay')?.classList.remove('show');
            //     // document.getElementById('categoryMenu')?.classList.remove('category-container-hide');
            //     document.getElementById('sidebarMenu').classList.remove('translatein');
            //     if (isRecipeDisplayPage && isRecipeOpen) {
            //         console.log('closeDisplayRecipe');
            //         const event = new CustomEvent('closeDisplayRecipe');
            //         window.dispatchEvent(event);
            //     }
            // }
            // checkboxRef.current.checked = !checkboxRef.current.checked;
            // document.getElementById('sidebarMenu').classList.toggle('translatein');
        }
        
        // setIsMenuOpen(!isMenuOpen);
    };
    
    
    // useEffect(() => {
    //     if (checkboxRef.current) {
    //         checkboxRef.current.checked = isMenuOpen;
    //     }
    // }, [isMenuOpen]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 10) {
                setIsFloating(true);
            } else {
                setIsFloating(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div id="navbar" className={`div-navbar disable-select ${isFloating ? 'div-navbar-floating' : ''}`}>
            <div className="div-navbar-left">
                <input
                    ref={checkboxRef}
                    type="checkbox"
                    className="openSidebarMenu"
                    id="menuToggle"
                    onChange={handleMenuToggle}
                />
                <label htmlFor="menuToggle" className="menu-toggle disable-select">
                    <div className="spinner diagonal part-1"></div>
                    <div className="spinner horizontal"></div>
                    <div className="spinner diagonal part-2"></div>
                </label>
            </div>
            <div className="div-navbar-center disable-select">
                <>
                    {auth?.access_token ? (
                        <Link to="/home" onClick={handleMenuToggle}>
                            Let's Cook
                        </Link>
                    ) : (
                        <Link to="/" onClick={handleMenuToggle}>
                            Let's Cook
                        </Link>
                    )}
                </>
            </div>
            <div className="div-navbar-right"></div>
        </div>
    );
}

export default Navbar;