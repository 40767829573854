import { Link } from 'react-router-dom'

const Missing = () => {
    return (
        <section className="disable-select">
            <h1>Oops!</h1>
            <p>Page Not Found</p><br/>
            <div className="flexGrow">
                <Link to="/">Visit Our Homepage</Link>
            </div>
        </section>
    )
}

export default Missing
