import { Routes, Route } from 'react-router-dom';

import GenerateRecipe from './pages/GenerateRecipe';
import Index from './pages/Index';
import Layout from './components/Layout';
import Missing from './components/Missing';
import PersistLogin from './components/PersistLogin';
import RequireAuth from './components/RequireAuth';
import AdminDashboard from './pages/AdminDashboard';
import Home from './pages/Home';
import Login from './pages/Login';
import Profile from './pages/Profile';
import Register from './pages/Register';
import Unauthorized from './pages/Unauthorized';
import { roles } from './utils/appConfig';

function App() {
    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route path="login" element={<Login />} />
                <Route path="register" element={<Register />} />
                <Route path="unauthorized" element={<Unauthorized />} />
                <Route path="/" element={<Index />} />
                
                <Route element={<PersistLogin />}>
                    <Route path="/home" element={<Home />} />
                    <Route path="/generate_recipe" element={<GenerateRecipe />} />
                    <Route element={<RequireAuth allowedRoles={[roles.USER]} requireFresh={true} />}>
                        <Route path="profile" element={<Profile />} />
                    </Route>
                    <Route element={<RequireAuth allowedRoles={[roles.ADMIN]} />}>
                        <Route path="admin_dashboard" element={<AdminDashboard />} />
                    </Route>
                </Route>

                <Route path="*" element={<Missing />} />
            </Route>
        </Routes>
    );
}

export default App;
