import { useState } from 'react'

import { FaTrash } from 'react-icons/fa'

import Confirmation from './Confirmation'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { ingredient_name_regex, ingredient_quantity_regex } from '../utils/regex'

const EditIngredient = ({ recipeId, ingredient, ingredientUnits, isEditMode, onDelete, onCreate, onCreateCancel, onUpdate, onAlert }) => {
    const axiosPrivate = useAxiosPrivate();
    const [editedIngredient, setEditedIngredient] = useState(ingredient);
    const [isAlertTriggered, setIsAlertTriggered] = useState(false);
    const [isIngredientDetailEdited, setIsIngredientDetailEdited] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [newIngredient, setNewIngredient] = useState({
        name: '',
        quantity: '',
        unit_id: ''
    });
    
    const handleClickCancel = () => {
        onAlert('');

        if (!ingredient) {
            onCreateCancel();
        } else {
            setEditedIngredient(ingredient);
            setIsIngredientDetailEdited(false);
        }
    };

    const handleClickDelete = () => {
        onAlert('');
        setShowConfirmation(true);
    };
    
    const handleConfirmCancel = () => {
        setShowConfirmation(false);
    };

    const handleConfirmDelete = async () => {
        try {
            await axiosPrivate.delete(`/app/recipe/${recipeId}/ingredient/${ingredient.id}`);
            onDelete(ingredient.id);
        } catch (err) {
            setIsAlertTriggered(true);
            onAlert(err.response?.data?.detail || 'error deleting ingredient');
        } finally {
            setShowConfirmation(false);
        }
    };

    const handleInputChange = (e) => {
        if (isAlertTriggered) {
            setIsAlertTriggered(false);
            onAlert('');
        }

        if (e.target.value !== ingredient[e.target.id]) {
            setIsIngredientDetailEdited(true);
        } else {
            setIsIngredientDetailEdited(false);
        }
        
        if (ingredient) {
            setEditedIngredient({
                ...editedIngredient,
                [e.target.id]: e.target.value,
            });
        } else {
            setNewIngredient({
                ...newIngredient,
                [e.target.id]: e.target.value,
            });
        }
    };

    const handleSubmitForm = async (e) => {
        e.preventDefault();
        let ingredientToValidate;

        if (!ingredient) {
            ingredientToValidate = newIngredient;
        } else {
            ingredientToValidate = editedIngredient;
        }

        if (!ingredient_name_regex.test(ingredientToValidate.name)) {
            setIsAlertTriggered(true);
            onAlert('invalid ingredient name format: must be 2-100 characters long and contain only alphanumeric characters, underscores, spaces, parenthesis, hyphens, commas, and periods');
            return;
        }

        if (!ingredient_quantity_regex.test(ingredientToValidate.quantity)) {
            setIsAlertTriggered(true);
            onAlert('invalid ingredient quantity format: must be a number, fraction, decimal, or mixed number');
            return;
        }

        try {
            if (!ingredient) {
                const response = await axiosPrivate.post(`/app/recipe/${recipeId}/ingredient`, newIngredient);
                onCreate(response.data);
                onAlert('');
            } else {
                const { id, ...editedIngredientBody } = editedIngredient;
                const response = await axiosPrivate.put(`/app/recipe/${recipeId}/ingredient/${ingredient.id}`, editedIngredientBody);
                setEditedIngredient(response.data);
                onUpdate(response.data);
                setIsIngredientDetailEdited(false);
                onAlert('');
            }
        } catch (err) {
            setIsAlertTriggered(true);
            onAlert(ingredient ? 'error updating ingredient': 'error creating ingredient');
            onCreateCancel();
        }
    };

    return (
        <>
            <form id={ingredient.id} className="ingredients-form" onSubmit={handleSubmitForm}>
                <div className="ingredient-form-inputs">
                    <div>
                        <input
                            id="name"
                            type="text"
                            placeholder="Ingredient"
                            value={ingredient ? editedIngredient.name : newIngredient.name}
                            onChange={(e) => handleInputChange(e)}
                            readOnly={!isEditMode} 
                            required
                        />
                    </div>
                    <div className="quantity-row">
                        <input
                            type="text"
                            id="quantity"
                            placeholder="Quantity"
                            className="quantity"
                            value={ingredient ? editedIngredient.quantity : newIngredient.quantity}
                            onChange={(e) => handleInputChange(e)}
                            readOnly={!isEditMode} 
                            required
                        />
                        <select
                            id="unit_id"
                            value={editedIngredient.unit_id}
                            onChange={(e) => handleInputChange(e)}
                            disabled={!isEditMode} 
                            required
                        >
                            <option value="">-- Measurement --</option>
                            {ingredientUnits.map((ingredientUnit) => (
                                <option key={ingredientUnit.id} value={ingredientUnit.id}>
                                    {ingredientUnit.name}
                                </option>
                            ))}
                        </select>
                        {isEditMode && (
                            <button
                                type="button"
                                className="trash-button disable-select"
                                onClick={() => handleClickDelete()}
                                disabled={!ingredient}
                            >
                                <FaTrash />
                            </button>
                        )}
                    </div>
                </div>
                {(isEditMode && isIngredientDetailEdited) || !ingredient ? (
                    <div className="button-group">
                        <button type="submit" className="save-button disable-select">Save</button>
                        <button type="button" className="cancel-button disable-select" onClick={handleClickCancel}>Cancel</button>
                    </div>
                ): null}
            </form>
            <div className={`sub-overlay ${showConfirmation ? "show" : ""}`}>
                {showConfirmation && (
                    <Confirmation
                        message={`Are you sure you want to delete ${ingredient.name}?`}
                        onConfirm={handleConfirmDelete}
                        onCancel={handleConfirmCancel}
                    />
                )}
            </div>
        </>
    )
}

export default EditIngredient