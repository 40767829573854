import { useRef, useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { jwtDecode } from 'jwt-decode';

import AlertMessage from '../components/AlertMessage';
import useAuth from '../hooks/useAuth';
import useInput from '../hooks/useInput';
import useToggle from '../hooks/useToggle';
import axios from '../utils/axios';
import { tenant } from '../utils/appConfig';
import { username_regex, password_regex } from '../utils/regex';

import '../assets/styles/login.css';

const Login = () => {
    const { setAuth } = useAuth();
    const [user, resetUser, userAttribs] = useInput('user', '');
    const navigate = useNavigate();
    const inputRef = useRef();
    const [pwd, setPwd] = useState('');
    const [alertMsg, setAlertMsg] = useState('');
    const [check, toggleCheck] = useToggle('persist', false);

    const handleSubmitForm = async (e) => {
        e.preventDefault();

        if (!username_regex.test(user)) {
            setAlertMsg('invalid username: it must be 3-30 characters long and contain only letters, numbers, and underscores');
            return;
        }

        if (!password_regex.test(pwd)) {
            setAlertMsg('the password must be 8-50 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character (@$!%*?&#\')');
            return;
        }
        
        const formData = new URLSearchParams();
        formData.append('username', user);
        formData.append('password', pwd);
        formData.append('tenant', tenant);
        
        try {
            const response = await axios.post('/auth/token', formData.toString(), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                withCredentials: true
            });

            const access_token = response.data.access_token;
            const decoded = jwtDecode(access_token);
            const roles = decoded?.roles || [];
            const rolesToInt = roles.map(role => parseInt(role));
            const userObject = {
                id: decoded?.sub,
                aud: decoded?.aud,
                name: user,
                roles: rolesToInt
            }

            setAuth({ user: userObject, access_token });
            resetUser();
            setPwd('');
            navigate('/home');
        } catch (error) {
            if (!error?.response) {
                setAlertMsg('no server response');
            } else if (error.response?.status === 422) {
                setAlertMsg('missing fields');
            } else {
                setAlertMsg(error.response?.data.detail);
            }
        }
    };
    
    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    useEffect(() => {
        setAlertMsg('');
    }, [user, pwd]);

    return (
        <>
            <AlertMessage message={alertMsg} category="error" onClose={() => setAlertMsg('')}/>
            <section className="section-login disable-select">
                <div className="login-container">
                    <h1>Sign In</h1>
                    <form onSubmit={handleSubmitForm} className="form-login">
                        <label htmlFor="username">Username</label>
                        <input
                            type="text"
                            id="username"
                            placeholder=""
                            ref={inputRef}
                            autoComplete="off"
                            {...userAttribs}
                            required
                        />
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            placeholder=""
                            onChange={(e) => {setPwd(e.target.value); document.getElementById('menuToggle').checked && document.getElementById('menuToggle').click();}}
                            value={pwd}
                            required
                        />
                        <div className="persist-check">
                            <input
                                type="checkbox"
                                id="persist"
                                onChange={(e) => {toggleCheck(); document.getElementById('menuToggle').checked && document.getElementById('menuToggle').click();}}
                                checked={check}
                            />
                            <label htmlFor="persist">Trust This Device</label>
                        </div>
                        <button className="login-btn">Sign In</button>
                    </form>
                    <p>Need an Account?</p>
                    <p><Link to="/register">Sign Up</Link></p>
                </div>
            </section>
        </>
    );
}

export default Login;