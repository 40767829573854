import { useState, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';

import Navbar from './Navbar';
import SidebarMenu from './SidebarMenu';
import useLogout from '../hooks/useLogout';

import '../assets/styles/layout.css';

const Layout = () => {
    const logout = useLogout();
    const navigate = useNavigate();
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [promptMessage, setPromptMessage] = useState('Install the app for quicker access.');
    const [showInstallPrompt, setShowInstallPrompt] = useState(false);

    const handleLogout = async () => {
        await logout();
        navigate('/');
    };

    const handleInstallClick = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    // console.log('User accepted the A2HS prompt');
                } else {
                    // console.log('User dismissed the A2HS prompt');
                }
                setDeferredPrompt(null);
                setShowInstallPrompt(false);
            });
        } else {
            const isIos = /iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase());
    
            if (isIos) {
                setPromptMessage('To install this app on IOS, open the Share menu and select "Add to Home Screen".');
            } else {
                setPromptMessage('It looks like you already have the app installed. Check your home screen.');
            }
        }
    };

    useEffect(() => {
        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            setDeferredPrompt(e);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        const isAppInstalled = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;

        if (!isAppInstalled) {
            const timer = setTimeout(() => {
                setPromptMessage('Install the app for quicker access.');
                setShowInstallPrompt(true);
            }, 30000);

            return () => {
                window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
                clearTimeout(timer);
            };
        } else {
            return () => {
                window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
            };
        }
    }, []);

    useEffect(() => {
        const preventBack = (event) => {
            console.log('popstate event triggered');
            window.history.pushState(null, '', window.location.href);
            navigate(window.location.pathname);
        };

        window.history.pushState(null, '', window.location.href);
        window.addEventListener('popstate', preventBack);

        return () => {
            window.removeEventListener('popstate', preventBack);
        };
    }, [navigate]);

    return (
        <main className="div-main">
            <Navbar />
            <SidebarMenu onLogout={handleLogout} />
            <div id="mainBody" className="div-main-body"></div>
            <Outlet />
            {showInstallPrompt && (
                <div className="install-prompt">
                    <p>{promptMessage}</p>
                    <div className="button-group">
                        <button onClick={handleInstallClick}>Install</button>
                        <button onClick={() => setShowInstallPrompt(false)}>Dismiss</button>
                    </div>
                </div>
            )}
        </main>
    );
};

export default Layout;