// import useAuth from '../hooks/useAuth';
// import { useLocation, Navigate, Outlet } from 'react-router-dom';

// const RequireAuth = ({ allowedRoles }) => {
//     const { auth } = useAuth();
//     const location = useLocation();

//     return (
//         auth?.user?.roles?.find(role => allowedRoles.includes(role))
//             ? <Outlet />
//             : auth?.access_token
//                 ? <Navigate to="/unauthorized" state={{ from: location }} replace />
//                 : <Navigate to="/login" state={{ from: location }} replace />
//     );
// }

// export default RequireAuth;

// import { useState } from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { jwtDecode } from 'jwt-decode';
import TokenFreshForm from './TokenFreshForm';

const RequireAuth = ({ allowedRoles, requireFresh = false }) => {
    const { auth, setAuth } = useAuth(); // Ensure you have a way to update the auth state
    const location = useLocation();
    // const [showFreshForm, setShowFreshForm] = useState(false);

    const isTokenFresh = (token) => {
        if (!token) return false;
        const { fresh } = jwtDecode(token);
        return fresh === true;
    };

    const handleFreshToken = async (token) => {
        setAuth(prev => {
            return {
                ...prev,
                access_token: token
            }
        });

        // setShowFreshForm(false);
    };

    if (requireFresh && !isTokenFresh(auth.access_token)) {
        return <TokenFreshForm onFreshToken={handleFreshToken} />;
    }

    return (
        auth?.user?.roles?.find(role => allowedRoles.includes(role))
            ? <Outlet />
            : auth?.access_token
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/" state={{ from: location }} replace />
    );
};

export default RequireAuth;
