import { useState, useRef } from 'react';

import { FaInfoCircle, FaEdit, FaTrash } from 'react-icons/fa';

import Confirmation from './Confirmation';
import EditRecipe from './EditRecipe';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const EditCard = ({ recipe, categories, onUpdate, onDelete, onAlert, onClickDetails }) => {
    const axiosPrivate = useAxiosPrivate();
    const scrollableDivRef = useRef(null);
    const [isEditing, setIsEditing] = useState(false);
    const [recipeAction, setRecipeAction] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleClickDelete = () => {
        onAlert('');
        setRecipeAction(false);
        setIsEditing(false);
        setShowConfirmation(true);
    };
    
    const handleConfirmCancel = () => {
        setShowConfirmation(false);
    };    

    const handleConfirmDelete = async () => {
        try {
            await axiosPrivate.delete(`/app/recipe/${recipe.id}`);
            onDelete(recipe.id);
        } catch (err) {
            onAlert(err.response?.data?.detail || 'error deleting recipe');
        } finally {
            setShowConfirmation(false);
        }
    };

    const handleItemClosed = () => {
        setRecipeAction(false);
    };

    return (
        <>
            <div className="list-item-container disable-select">
                <div className={`item-name ${recipe.is_published === 1 ? 'published' : ''} `}>
                    <p onClick={() => {onClickDetails(recipe.id)}}>{recipe.name}</p>
                </div>
                <div className="item-icons-container">
                    <div>
                        <FaInfoCircle 
                            className="item-icon info-icon disable-select" 
                            onClick={() => {onClickDetails(recipe.id)}} 
                            title="View Details" 
                        />
                        <FaEdit 
                            className="item-icon edit-icon disable-select" 
                            onClick={() => {onClickDetails(recipe.id, true)}} 
                            title="Edit Recipe" 
                        />
                        <FaTrash 
                            className="item-icon delete-icon disable-select" 
                            onClick={handleClickDelete} 
                            title="Delete Recipe" 
                        />
                    </div>
                </div>
            </div>
            <div ref={scrollableDivRef} className={`sub-overlay ${recipeAction ? "show" : ""}`}>
                {recipeAction && (
                    <EditRecipe 
                        recipe={recipe} 
                        categories={categories} 
                        isEditing={isEditing} 
                        onUpdate={onUpdate} 
                        onClose={handleItemClosed} 
                        onAlert={onAlert}
                    />
                )}
            </div>
            <div className={`sub-overlay ${showConfirmation ? "show" : ""}`}>
                {showConfirmation && (
                    <Confirmation
                        message={`Are you sure you want to delete ${recipe.name}?`}
                        onConfirm={handleConfirmDelete}
                        onCancel={handleConfirmCancel}
                    />
                )}
            </div>
        </>
    );
};

export default EditCard;
