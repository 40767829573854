import '../assets/styles/alertMessage.css';

const AlertMessage = ({ message, category, onClose }) => {
    return (
        <div className={`popup-alert alert-${category} ${message ? "translatein" : ""}`}>  
            <p className={`alert-${category} disable-select`}>{message}</p>
            <div className="div-close disable-select" onClick={onClose}>X</div>
        </div>
    );
};

export default AlertMessage;