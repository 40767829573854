import { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import AlertMessage from '../components/AlertMessage';
import axios from '../utils/axios';
import { tenant } from '../utils/appConfig';
import { username_regex, password_regex, email_regex } from '../utils/regex';

import '../assets/styles/login.css';

const Register = () => {
    const inputRef = useRef();
    const [alertMsg, setAlertMsg] = useState('');
    const [isAlertTriggered, setIsAlertTriggered] = useState(false);
    const [category, setCategory] = useState('error');
    const [newUser, setNewUser] = useState({
        name: '',
        email_address: '',
        password: '',
        password_confirm: '',
        tenant_id: tenant
    });

    const handleInputChange = (e) => {
        if (isAlertTriggered) {
            setIsAlertTriggered(false);
            setAlertMsg('');
        }

        if (e.target.name === 'name' || e.target.name === 'email_address') {
            e.target.value = e.target.value.toLowerCase();
        }
        
        setNewUser(prevRecipe => ({
            ...prevRecipe,
            [e.target.name]: e.target.value
        }));
    };

    const handleSubmitForm = async (e) => {
        e.preventDefault();
        
        if (!username_regex.test(newUser.name)) {
            setAlertMsg('invalid username: it must be 3-30 characters long and contain only letters, numbers, and underscores');
            return;
        }

        if (!email_regex.test(newUser.email_address)) {
            setAlertMsg('invalid email format');
            return;
        }

        if (!password_regex.test(newUser.password)) {
            setAlertMsg('the password must be 8-50 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character (@$!%*?&#\')');
            return;
        }

        if (newUser.password !== newUser.password_confirm) {
            setAlertMsg('the passwords do not match');
            return;
        }

        try {
            const { password_confirm, ...newUserBody } = newUser;
            const response = await axios.post('/api/user', newUserBody);

            if (response.status === 200) {
                setAlertMsg('user created successfully: login to continue');
                setCategory('success');
            }
        } catch (err) {
            setAlertMsg(err.response?.data?.detail || 'error creating user');
            setCategory('error');
        }
    };

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    return (
        <>
            <AlertMessage message={alertMsg} category={category} onClose={() => setAlertMsg('')}/>
            <section className="section-login disable-select">
                <div className="login-container">
                    <h1>Sign Up</h1>
                    <form onSubmit={handleSubmitForm} className="form-login">
                        <label htmlFor="name">Username</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder=""
                            ref={inputRef}
                            autoComplete="off"
                            onChange={handleInputChange}
                            required
                        />
                        <label htmlFor="email_address">Email</label>
                        <input
                            type="email"
                            id="email_address"
                            name="email_address"
                            placeholder=""
                            onChange={handleInputChange}
                            required
                        />
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            placeholder=""
                            onChange={handleInputChange}
                            required
                        />
                        <label htmlFor="password_confirm">Confirm Password</label>
                        <input
                            type="password"
                            id="password_confirm"
                            name="password_confirm"
                            placeholder=""
                            onChange={handleInputChange}
                            required
                        />
                        <br></br>
                        <button className="login-btn">Register</button>
                    </form>
                    <p>Already Registered?</p>
                    <p><Link to="/login">Sign In</Link></p>
                </div>
            </section>
        </>
    )
}

export default Register
