
import '../assets/styles/tokenFreshForm.css';
import AlertMessage from './AlertMessage';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { password_regex } from '../utils/regex';

import { useState, useEffect } from 'react';

const TokenFreshForm = ({ onFreshToken }) => {
    const [password, setPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const axiosPrivate = useAxiosPrivate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!password_regex.test(password)) {
            setErrMsg('the password must be 8-50 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character (@$!%*?&#\')');
            return;
        }

        try {
            const formData = new URLSearchParams();
            formData.append('password', password);
            
            const response = await axiosPrivate.post('/auth/token/fresh', formData.toString(), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });

            onFreshToken(response.data?.access_token);
        } catch (err) {
            setErrMsg(err.response?.data?.detail || 'error getting fresh token');
        }
    };

    const handleInputChange = (e) => {
        errMsg && setErrMsg('');
        
        setPassword(e.target.value);
    };

    useEffect(() => {
        document.getElementById('password')?.focus();
    }, []);

    return (
        <div className="fresh-container">
            <div className="create-overlay">
                <AlertMessage message={errMsg} category="error" onClose={() => setErrMsg('')} />
                <div className="fresh-form-container">
                    <p>Enter your password to continue</p>
                    <br />
                    <form onSubmit={handleSubmit} className="form-login">
                        <div className="form-input-container">
                        <input
                            id="password"
                            type="password"
                            value={password}
                            onChange={handleInputChange}
                            required
                        />
                        </div>
                        <div className="button-group">
                            <button type="submit">Continue</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default TokenFreshForm;
