import { useState, useCallback } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const useFetchRecipeDetail = () => {
    const axiosPrivate = useAxiosPrivate();
    const [fetchedRecipeDetail, setFetchedRecipeDetail] = useState(null);

    const fetchRecipeDetail = useCallback(async (recipeId) => {
        const controller = new AbortController();
        let isMounted = true;

        const getRecipeDetail = async () => {
            try {
                const response = await axiosPrivate.get(`/app/recipe/${recipeId}`, {
                    signal: controller.signal,
                });

                if (isMounted) {
                    setFetchedRecipeDetail(response.data);
                }
            } catch (err) {
                throw(err);
            }
        };

        try {
            await getRecipeDetail();
        } finally {
            isMounted = false;
            controller.abort();
        }
    }, [axiosPrivate]);

    return {
        fetchedRecipeDetail,
        setFetchedRecipeDetail, 
        fetchRecipeDetail,
    };
};

export default useFetchRecipeDetail;