import { useState } from 'react'

import { FaTrash } from 'react-icons/fa'

import Confirmation from './Confirmation'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { validate_description } from '../utils/regex'

const EditInstruction = ({ recipeId, instruction, isEditMode, onDelete, onCreate, onCreateCancel, onUpdate, onAlert }) => {
    const axiosPrivate = useAxiosPrivate();
    const [editedInstruction, setEditedInstruction] = useState(instruction);
    const [isAlertTriggered, setIsAlertTriggered] = useState(false);
    const [isInstructionDetailEdited, setIsInstructionDetailEdited] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [newInstruction, setNewInstruction] = useState({
        description: ''
    });

    const handleClickCancel = () => {
        onAlert('');

        if (!instruction) {
            onCreateCancel();
        } else {
            setEditedInstruction(instruction);
            setIsInstructionDetailEdited(false);
        }
    }

    const handleClickDelete = () => {
        onAlert('');
        setShowConfirmation(true);
    };

    const handleConfirmCancel = () => {
        setShowConfirmation(false);
    };

    const handleConfirmDelete = async () => {
        try {
            await axiosPrivate.delete(`/app/recipe/${recipeId}/instruction/${instruction.id}`);
            onDelete(instruction.id);
        } catch (err) {
            setIsAlertTriggered(true);
            onAlert(err.response?.data?.detail || 'error deleting instruction');
        } finally {
            setShowConfirmation(false);
        }
    };

    const handleInputChange = (e) => {
        if (isAlertTriggered) {
            setIsAlertTriggered(false);
            onAlert('');
        }

        if (e.target.value !== instruction[e.target.id]) {
            setIsInstructionDetailEdited(true);
        } else {
            setIsInstructionDetailEdited(false);
        }

        if (instruction) {
            setEditedInstruction(prevInstruction => ({
                ...prevInstruction,
                [e.target.id]: e.target.value
            }));
        } else {
            setNewInstruction(prevInstruction => ({
                ...prevInstruction,
                [e.target.id]: e.target.value
            }));
        }
    };

    const handleSubmitForm = async (e) => {
        e.preventDefault();
        let instructionToValidate;

        if (!instruction) {
            instructionToValidate = newInstruction;
        } else {
            instructionToValidate = editedInstruction;
        }

        try {
            validate_description(instructionToValidate.description);
        } catch (err) {
            setIsAlertTriggered(true);
            onAlert(err.message);
            return;
        }

        try {
            if (!instruction) {
                const response = await axiosPrivate.post(`/app/recipe/${recipeId}/instruction`, newInstruction);
                onCreate(response.data);
                onAlert('');
            } else {
                const { id, image_url, ...editedInstructionBody } = editedInstruction;
                const response = await axiosPrivate.put(`/app/recipe/${recipeId}/instruction/${instruction.id}`, editedInstructionBody);
                setEditedInstruction(response.data);
                onUpdate(response.data);
                setIsInstructionDetailEdited(false);
                onAlert('');
            }
        } catch (err) {
            setIsAlertTriggered(true);
            onAlert(err.response?.data?.detail || 'error creating instruction');
        }
    }

    return (
        <>
            <form id={instruction.id} className="instructions-form" onSubmit={handleSubmitForm}>
                <div className="instructions-form-inputs">
                    <textarea
                        id="description"
                        name="description"
                        value={instruction ? editedInstruction.description : newInstruction.description}
                        onChange={handleInputChange}
                        readOnly={!isEditMode}
                        required
                    />
                    {isEditMode && (
                        <button
                            type="button"
                            className="trash-button disable-select"
                            onClick={() => handleClickDelete()}
                            disabled={!instruction}
                        >
                            <FaTrash />
                        </button>
                    )}
                </div>
                {(isEditMode && isInstructionDetailEdited) || !instruction ? (
                    <div className="button-group">
                        <button type="submit" className="save-button disable-select">Save</button>
                        <button type="button" className="cancel-button disable-select" onClick={handleClickCancel}>Cancel</button>
                    </div>
                ): null}
            </form>
            <div className={`sub-overlay ${showConfirmation ? "show" : ""}`}>
                {showConfirmation && (
                    <Confirmation
                        message={`Are you sure you want to delete this instruction?`}
                        onConfirm={handleConfirmDelete}
                        onCancel={handleConfirmCancel}
                    />
                )}
            </div>
        </>
    )
}

export default EditInstruction