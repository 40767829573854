import { useState, useCallback } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const useFetchRecipes = () => {
    const axiosPrivate = useAxiosPrivate();
    const [fetchedRecipes, setFetchedRecipes] = useState([]);

    const fetchRecipes = useCallback(async (isAdmin = false, recipeCategory = null ) => {
        const controller = new AbortController();
        let endpoint;
        let isMounted = true;

        if (isAdmin) {
            endpoint = '/app/recipes/admin';
        } else if (!isAdmin && recipeCategory === null) {
            throw new Error('recipeCategory must be provided if not admin');
        } else {
            endpoint = `/app/recipes/${recipeCategory}`;
        }

        const getRecipes = async () => {
            try {
                const response = await axiosPrivate.get(endpoint, {
                    signal: controller.signal,
                });

                if (isMounted) {
                    // setFetchedRecipes(response.data);
                    setFetchedRecipes(prev => [...prev, ...(response.data || [])]);
                }
            } catch (err) {
                throw(err);
            }
        };

        try {
            return await new Promise((resolve, reject) => {
                getRecipes().then(resolve).catch(reject);
            });
        } finally {
            isMounted = false;
            controller.abort();
        }
    }, [axiosPrivate]);

    return {
        fetchedRecipes,
        setFetchedRecipes,
        fetchRecipes,
    };
};

export default useFetchRecipes;